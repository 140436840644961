.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ReactModal__Body--open {
  padding: 0 !important;
}

/* .ReactModal__Overlay {
  opacity: 0;
  padding: 0 !important;
  background-color: transparent;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  background-color: transparent;
  padding: 0 !important;
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  background-color: transparent;
  padding: 0;
  opacity: 0;
} */

.ReactModal__Overlay {
  opacity: 0;
  padding: 0 !important;
  background-color: transparent;
  transform: translateY(100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  background-color: transparent;
  padding: 0 !important;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  background-color: transparent;
  padding: 0 !important;
  transform: translateY(100px);
}